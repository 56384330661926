html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  #root, .dark-theme, .light-theme {
    min-height: 100%; /* Changed from 100vh to 100% */
  }
  
  
  .light-theme {
    background-color: white;
    color: black;
  }
  
  .dark-theme {
    background-color: black;
    color: white;
  }

  /* Dark theme hover effect */
.dark-theme .sidebar a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  
  /* Light theme hover effect */
  .light-theme .sidebar a:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #000;
  }
  
  
  .header {
    display: flex;
    justify-content: flex-end; /* Align the theme toggle to the right */
    padding: 10px 20px;
    position: relative; 
    z-index: 10;
  }
  
  .menu-icon {
    cursor: pointer;
    display: none; /* Hidden on wide screens */
    position: absolute;
    left: 10px; /* Positioning to the left */
    top: 10px; /* Adjust as necessary for vertical alignment */
  }
  
  .sidebar {
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #333;
    color: white;
    padding-top: 20px;
    z-index: 5;
  }

/* Hover Effect for Links (common for both themes) */
.sidebar a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  
  /* Dark Theme Styles */
  .dark-theme .sidebar {
    background: #333;
    color: white;
  }
  
  .dark-theme .sidebar li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  /* Light Theme Styles */
  .light-theme .sidebar {
    background: #f0f0f0;
    color: black;
  }
  
  .light-theme .sidebar li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  
  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar li {
    /* Remove padding from li and maintain the border for separation */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0; /* Reset margin */
  }
  
  .sidebar li:last-child {
    border-bottom: none;
  }
  
  .sidebar a {
    color: inherit;
    text-decoration: none;
    display: flex; /* Keeps the icon and text in a row */
    align-items: center; /* Vertically centers the icon and text */
    padding: 15px 20px;
    transition: background-color 0.3s, color 0.3s;
    /* Remove line-height to allow flexbox to control the height */
  }
  
  .sidebar a span {
    margin-left: 8px; /* Adjusts space between the icon and the text */
  }
  
  /* Adjust hover effect for better visibility */
  .sidebar a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
    
  .light-theme .sidebar a.active {
    background-color: rgba(0, 0, 0, 0.1); /* Adjust background for visibility */
    color: #0056b3; /* Darker blue color for active link in light theme */
  }
  
  /* Dark Theme Active Link Styling */
  .dark-theme .sidebar a.active {
    background-color: rgba(255, 255, 255, 0.2); /* Slightly more visible highlight for the active link */
    color: #87CEEB; /* Lighter blue color for active link in dark theme */
  }

  .dark-theme .button-hover-effect:hover svg {
    color: #addbed !important; /* White icon color on hover in dark theme */
  }
  
  /* Hover effect for light theme */
  .light-theme .button-hover-effect:hover svg {
    color: #003875 !important; /* Light blue icon color on hover in light theme */
  }
    
  .content {
    padding: 20px;
    margin-left: 250px;
    background-color: inherit; /* This ensures the content inherits the body's background color */
    min-height: calc(100vh - 40px); /* Adjust height to ensure it covers the viewport height minus padding */
  }
  
  @media (max-width: 768px) {
    .menu-icon {
      display: block; /* Show on narrower screens */
    }
  
    .sidebar {
      transform: translateX(-100%);
      transition: transform 0.3s ease;
    }
  
    .sidebar.open {
      transform: translateX(0);
    }
  
    .content {
        margin-left: 0;
        padding: 20px;
      }
    
  }
  