.profile-container {
  padding: 20px;
  margin-left: 250px; /* Adjust this value if the sidebar width changes */
}

/* Add this media query to adjust styles for screens smaller than 768px */
@media (max-width: 768px) {
  .profile-container {
    margin-left: 0; /* Remove the left margin on mobile devices */
  }
}

.dark-theme .profile-container {
  background-color: #121212; /* Dark mode background */
  color: #ffffff; /* Dark mode text color */
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Adjust button width and center them */
.btn {
  width: 100%; /* Make buttons stretch to the full width of their parent container */
  padding: 8px 15px; /* Adjust padding as needed */
  margin-bottom: 10px; /* Keep your existing margin for spacing */
  border: none;
  cursor: pointer;
  margin: 0 auto; /* Center button */
  display: block; /* Make button a block element to respect margin auto */
  color: #000; /* Ensuring text color is visible */
}

.dark-theme .btn {
  background-color: #444; /* Darker button for contrast */
  color: #fff; /* White text for readability */
}

.btn.danger {
  background-color: red; /* Example for a delete button */
}

.dark-theme .btn.danger {
  background-color: #b00; /* Dark theme specific delete button color */
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px; /* Add a bottom margin to create spacing between groups */
}


input[type="email"], input[type="password"] {
  width: calc(100% - 16px); /* Full width taking padding into account */
  margin: 0 auto; /* Center inputs */
  display: block; /* Ensure inputs are treated as block elements */
  padding: 8px; /* Improved padding for better readability */
}

.dark-theme input[type="email"], .dark-theme input[type="password"] {
  background-color: #333; /* Darker background for inputs */
  color: #fff; /* White text for readability */
  border: 1px solid #666; /* Slightly lighter border for contrast */
}

.section-container {
  background-color: #f9f9f9; /* Light theme background */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  max-width: 600px;
  width: 100%;
}

.dark-theme .section-container {
  background-color: #222; /* Dark theme background for sections */
  color: #fff; /* Ensuring text is readable */
  border: 1px solid #444; /* Dark theme border color */
}

.section-title {
  font-size: 18px;
  margin-bottom: 15px;
}